import { Color } from './state';

function getPropertyValue(prop: string): string {
  return getComputedStyle(document.documentElement).getPropertyValue(prop);
}

function composeColor(key: string): string {
  return [
    '#',
    parseInt(getPropertyValue(`--player-${key}-r`), 10).toString(16),
    parseInt(getPropertyValue(`--player-${key}-g`), 10).toString(16),
    parseInt(getPropertyValue(`--player-${key}-b`), 10).toString(16),
  ].join('');
}

function getColor(color: Color): string {
  switch (color) {
    case Color.BLUE:
      return composeColor('blue');
    case Color.GREEN:
      return composeColor('green');
    case Color.RED:
      return composeColor('red');
    case Color.YELLOW:
      return composeColor('yellow');
  }
}

export function getColors(colors: Color[]): string[] {
  return colors.map(getColor);
}
