import CloseButton from './CloseButton';

function HowToPlay() {
  return (
    <section className="container rows">
      <header className="header">
        <h1>How to play</h1>
        <CloseButton />
      </header>
      <h2>Gameplay</h2>
      <p>Your first piece must occupy one of the four corners of the board.</p>
      <p>
        Each of your new pieces must touch one of your previous pieces by the
        corner(s) only.
      </p>
      <p>
        Pieces of the same color are not allowed to have edge-to-edge contact
        (but can contact other colors by their edges). No pieces may overlap or
        extend past the edge of the board.
      </p>
      <p>Each piece has a unique shape and can be played only once.</p>
      <p>
        If you don't think you can play any more pieces, use the "Forfeit"
        button to skip your remaining turns.
      </p>
      <h2>Scoring</h2>
      <p>
        The game ends when all players have either played all their pieces or
        forfeited their turns.
      </p>
      <p>
        Players who have played all their pieces receive 15 points, or 20 points
        if the final piece played was the monomino (the single small square).
      </p>
      <p>
        Unplayed pieces are worth -1 point per square (e.g., the domino piece,
        which would occupy 2 squares on the board, is worth -2 points if
        unplayed at the end of the game).
      </p>
      <p>The player with the highest score wins.</p>
    </section>
  );
}

export default HowToPlay;
