import {
  ALL_SHAPE_NAMES,
  Board,
  Color,
  GameState,
  Rotation,
  ShapeName,
} from './state';

const PRIMARY_SEPARATOR = '_';
const SECONDARY_SEPARATOR = '-';
const RADIX = 36;
const ROTATION_FACTOR = 90;
const OFFSET = 5;

const SHAPE_INDICES: [ShapeName, string][] = [
  ...ALL_SHAPE_NAMES,
].map((shapeName, i) => [shapeName, i.toString(RADIX)]);

const SHAPE_NAME_SERIALIZATION = new Map<ShapeName, string>(SHAPE_INDICES);

const SHAPE_NAME_DESERIALIZATION = new Map<string, ShapeName>(
  SHAPE_INDICES.map(([shapeName, index]) => [index, shapeName]),
);

function encodeUserInput(value: string): string {
  return encodeURIComponent(btoa(value));
}

function decodeUserInput(encoded: string): string {
  return atob(decodeURIComponent(encoded));
}

export function serialize(state: GameState): string {
  return [
    ...[
      state.players[Color.BLUE].name,
      state.players[Color.BLUE].phone,
      state.players[Color.GREEN].name,
      state.players[Color.GREEN].phone,
      state.players[Color.RED].name,
      state.players[Color.RED].phone,
      state.players[Color.YELLOW].name,
      state.players[Color.YELLOW].phone,
    ].map(encodeUserInput),
    state.currentTurn || 'null',
    state.board
      .map(piece =>
        [
          piece.color,
          SHAPE_NAME_SERIALIZATION.get(piece.shape),
          (piece.position.x + OFFSET).toString(RADIX),
          (piece.position.y + OFFSET).toString(RADIX),
          piece.rotation / ROTATION_FACTOR,
          Number(piece.flip),
        ].join(''),
      )
      .join(SECONDARY_SEPARATOR) || 'null',
    state.forfeited.join(SECONDARY_SEPARATOR) || 'null',
  ].join(PRIMARY_SEPARATOR);
}

export function deserialize(state: string): GameState {
  const [
    blueNameEncoded,
    bluePhoneEncoded,
    greenNameEncoded,
    greenPhoneEncoded,
    redNameEncoded,
    redPhoneEncoded,
    yellowNameEncoded,
    yellowPhoneEncoded,
    currentTurnEncoded,
    boardEncoded,
    forfeitedEncoded,
  ] = state.split(PRIMARY_SEPARATOR);
  const board: Board =
    boardEncoded === 'null'
      ? []
      : boardEncoded.split(SECONDARY_SEPARATOR).map(pieceEncoded => {
          const [
            colorEncoded,
            shapeNameEncoded,
            xEncoded,
            yEncoded,
            rotationEncoded,
            flipEncoded,
          ] = pieceEncoded.split('');
          return {
            color: colorEncoded as Color,
            shape: SHAPE_NAME_DESERIALIZATION.get(shapeNameEncoded)!,
            position: {
              x: parseInt(xEncoded, RADIX) - OFFSET,
              y: parseInt(yEncoded, RADIX) - OFFSET,
            },
            rotation: (parseInt(rotationEncoded, 10) *
              ROTATION_FACTOR) as Rotation,
            flip: Boolean(parseInt(flipEncoded, 10)),
          };
        });
  const forfeited: Color[] =
    forfeitedEncoded === 'null'
      ? []
      : (forfeitedEncoded.split(SECONDARY_SEPARATOR) as Color[]);
  return {
    players: {
      [Color.BLUE]: {
        name: decodeUserInput(blueNameEncoded),
        phone: decodeUserInput(bluePhoneEncoded),
      },
      [Color.GREEN]: {
        name: decodeUserInput(greenNameEncoded),
        phone: decodeUserInput(greenPhoneEncoded),
      },
      [Color.RED]: {
        name: decodeUserInput(redNameEncoded),
        phone: decodeUserInput(redPhoneEncoded),
      },
      [Color.YELLOW]: {
        name: decodeUserInput(yellowNameEncoded),
        phone: decodeUserInput(yellowPhoneEncoded),
      },
    },
    currentTurn:
      currentTurnEncoded === 'null' ? null : (currentTurnEncoded as Color),
    board,
    forfeited,
  };
}
