import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Game from './Game';
import HowToPlay from './HowToPlay';
import Settings from './Settings';
import Setup from './Setup';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/how-to-play">
          <HowToPlay />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/game">
          <Game />
        </Route>
        <Route path="/">
          <Setup />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
