import { useState } from 'react';
import ColorIcon from './ColorIcon';
import { Color, GameState } from './state';
import styles from './Setup.module.css';
import { smsUrl } from './sms';
import { useActivePhone } from './useActivePlayer';

function Setup() {
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [friend1Name, setFriend1Name] = useState('');
  const [friend1Phone, setFriend1Phone] = useState('');
  const [friend2Name, setFriend2Name] = useState('');
  const [friend2Phone, setFriend2Phone] = useState('');
  const [friend3Name, setFriend3Name] = useState('');
  const [friend3Phone, setFriend3Phone] = useState('');

  const [_, setPhone] = useActivePhone();

  return (
    <section className="container rows">
      <h1>Set up a new game</h1>
      <form
        className={styles.form}
        onSubmit={evt => {
          evt.preventDefault();
          const state: GameState = {
            players: {
              [Color.BLUE]: { name: friend2Name, phone: friend2Phone },
              [Color.GREEN]: { name: friend1Name, phone: friend1Phone },
              [Color.RED]: { name: userName, phone: userPhone },
              [Color.YELLOW]: { name: friend3Name, phone: friend3Phone },
            },
            currentTurn: Color.BLUE,
            board: [],
            forfeited: [],
          };
          setPhone(userPhone);
          window.location.href = smsUrl(state, Color.RED);
        }}
      >
        <span className={styles.sectionHeading}>You</span>
        <div className={styles.player}>
          <ColorIcon color={Color.RED} />
          <input
            required
            className={`input ${styles.input}`}
            type="text"
            placeholder="Your Name"
            value={userName}
            onChange={e => setUserName(e.target.value)}
          />
          <input
            required
            className={`input ${styles.input}`}
            type="tel"
            placeholder="Your Phone"
            value={userPhone}
            onChange={e => setUserPhone(e.target.value)}
          />
        </div>
        <span className={styles.sectionHeading}>Three friends</span>
        <div className={styles.player}>
          <ColorIcon color={Color.GREEN} />
          <input
            required
            className={`input ${styles.input}`}
            type="text"
            placeholder="Friend 1 Name"
            value={friend1Name}
            onChange={e => setFriend1Name(e.target.value)}
          />
          <input
            required
            className={`input ${styles.input}`}
            type="tel"
            placeholder="Friend 1 Phone"
            value={friend1Phone}
            onChange={e => setFriend1Phone(e.target.value)}
          />
        </div>
        <div className={styles.player}>
          <ColorIcon color={Color.BLUE} />
          <input
            required
            className={`input ${styles.input}`}
            type="text"
            placeholder="Friend 2 Name"
            value={friend2Name}
            onChange={e => setFriend2Name(e.target.value)}
          />
          <input
            required
            className={`input ${styles.input}`}
            type="tel"
            placeholder="Friend 2 Phone"
            value={friend2Phone}
            onChange={e => setFriend2Phone(e.target.value)}
          />
        </div>
        <div className={styles.player}>
          <ColorIcon color={Color.YELLOW} />
          <input
            required
            className={`input ${styles.input}`}
            type="text"
            placeholder="Friend 3 Name"
            value={friend3Name}
            onChange={e => setFriend3Name(e.target.value)}
          />
          <input
            required
            className={`input ${styles.input}`}
            type="tel"
            placeholder="Friend 3 Phone"
            value={friend3Phone}
            onChange={e => setFriend3Phone(e.target.value)}
          />
        </div>
        <button className="button" type="submit">
          Start
        </button>
      </form>
    </section>
  );
}

export default Setup;
