import { Color, GameState } from './state';
import { useSetting } from './useSetting';

const KEY = 'activePlayer';

export function useActivePhone(): [
  string | undefined,
  (phone: string | undefined) => void,
] {
  return useSetting(KEY);
}

export function useActivePlayer(
  state: GameState,
): [Color | undefined, (phone: string | undefined) => void] {
  const [phone, setPhone] = useActivePhone();
  const gamePlayers = new Map<string, Color>([
    [state.players[Color.BLUE].phone, Color.BLUE],
    [state.players[Color.GREEN].phone, Color.GREEN],
    [state.players[Color.RED].phone, Color.RED],
    [state.players[Color.YELLOW].phone, Color.YELLOW],
  ]);
  const storedColor = phone === undefined ? undefined : gamePlayers.get(phone);
  return [storedColor, setPhone];
}
