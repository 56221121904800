import { useHistory } from 'react-router-dom';
import styles from './CloseButton.module.css';

function CloseButton() {
  const history = useHistory();
  return (
    <button className={styles.button} onClick={() => history.goBack()}>
      <svg
        className={styles.close}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-0.1 -0.1 1.2 1.2"
      >
        <path d="M0,0 L1,1" />
        <path d="M1,0 L0,1" />
      </svg>
    </button>
  );
}

export default CloseButton;
