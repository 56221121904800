import { Color } from './state';

export default function playerClass(
  color: Color,
  styles: { [key: string]: string },
) {
  switch (color) {
    case Color.BLUE:
      return styles.blue;
    case Color.GREEN:
      return styles.green;
    case Color.RED:
      return styles.red;
    case Color.YELLOW:
      return styles.yellow;
  }
}
