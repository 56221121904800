import { smsUrl } from "./sms";
import { Color, GameState } from "./state";
import { useActivePlayer } from "./useActivePlayer";

function NewGameButton({ state }: { state: GameState }) {
  const [activePlayerColor] = useActivePlayer(state);
  if (activePlayerColor) {
    const playerMap = new Map<Color, Color>([
      [Color.BLUE, Color.GREEN],
      [Color.GREEN, Color.RED],
      [Color.RED, Color.YELLOW],
      [Color.YELLOW, Color.BLUE],
    ]);
    const newState: GameState = {
      players: {
        [Color.BLUE]: state.players[playerMap.get(Color.BLUE)!],
        [Color.GREEN]: state.players[playerMap.get(Color.GREEN)!],
        [Color.RED]: state.players[playerMap.get(Color.RED)!],
        [Color.YELLOW]: state.players[playerMap.get(Color.YELLOW)!],
      },
      currentTurn: activePlayerColor,
      board: [],
      forfeited: [],
    };
    const newActivePlayer = [...playerMap.entries()].find(
      ([_, v]) => v === activePlayerColor
    )![0];
    console.log(newState, newActivePlayer);
    return (
      <a className="button" href={smsUrl(newState, newActivePlayer)}>
        Rematch with this group
      </a>
    );
  } else {
    return null;
  }
}

export default NewGameButton;
