import { useEffect, useState } from 'react';

export function useSetting<T>(
  key: string,
  defaultValue?: T,
): [T | undefined, (value: T | undefined) => void] {
  const [value, setValue] = useState<T | undefined>(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue === null ? defaultValue : JSON.parse(storedValue);
  });
  useEffect(() => {
    if (value === undefined || value === defaultValue) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [value]);
  return [value, setValue];
}
