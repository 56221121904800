import { FC } from 'react';
import { Color } from './state';
import styles from './ColorIcon.module.css';

function title(color: Color): string {
  switch (color) {
    case Color.BLUE:
      return 'Blue';
    case Color.GREEN:
      return 'Green';
    case Color.RED:
      return 'Red';
    case Color.YELLOW:
      return 'Yellow';
  }
}

const ColorIcon: FC<{ color: Color; className?: string }> = ({
  color,
  className,
}) => (
  <i
    title={title(color)}
    className={[
      styles.colorIcon,
      color === Color.BLUE && styles.blue,
      color === Color.GREEN && styles.green,
      color === Color.RED && styles.red,
      color === Color.YELLOW && styles.yellow,
      className,
    ]
      .filter(Boolean)
      .join(' ')}
  />
);

export default ColorIcon;
