export enum ControlIconType {
  LEFT,
  UP,
  DOWN,
  RIGHT,
  ROTATE,
  FLIP,
}

function ControlIcon({ type }: { type: ControlIconType }) {
  switch (type) {
    case ControlIconType.LEFT:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="none"
          viewBox="0 0 30 30"
        >
          <title>Move piece left</title>
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
            d="M24.5 15h-18"
          />
          <path
            fill="currentColor"
            d="M9.83 9.55a1 1 0 00-1.66-1.1l1.66 1.1zm-1.66 12a1 1 0 001.66-1.1l-1.66 1.1zM5 15l-.83-.55a1 1 0 000 1.1L5 15zm3.17-6.55l-4 6 1.66 1.1 4-6-1.66-1.1zm-4 7.1l4 6 1.66-1.1-4-6-1.66 1.1z"
          />
        </svg>
      );
    case ControlIconType.UP:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="none"
          viewBox="0 0 30 30"
        >
          <title>Move piece up</title>
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
            d="M15 24.25v-18"
          />
          <path
            fill="currentColor"
            d="M20.45 9.58a1 1 0 001.1-1.66l-1.1 1.66zm-12-1.66a1 1 0 001.1 1.66l-1.1-1.66zM15 4.75l.55-.83a1 1 0 00-1.1 0l.55.83zm6.55 3.17l-6-4-1.1 1.66 6 4 1.1-1.66zm-7.1-4l-6 4 1.1 1.66 6-4-1.1-1.66z"
          />
        </svg>
      );
    case ControlIconType.DOWN:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="none"
          viewBox="0 0 30 30"
        >
          <title>Move piece down</title>
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
            d="M15 6v18"
          />
          <path
            fill="currentColor"
            d="M9.55 20.67a1 1 0 00-1.1 1.66l1.1-1.66zm12 1.66a1 1 0 00-1.1-1.66l1.1 1.66zM15 25.5l-.55.83a1 1 0 001.1 0L15 25.5zm-6.55-3.17l6 4 1.1-1.66-6-4-1.1 1.66zm7.1 4l6-4-1.1-1.66-6 4 1.1 1.66z"
          />
        </svg>
      );
    case ControlIconType.RIGHT:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="none"
          viewBox="0 0 30 30"
        >
          <title>Move piece right</title>
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
            d="M5.75 15h18"
          />
          <path
            fill="currentColor"
            d="M20.42 20.45a1 1 0 001.66 1.1l-1.66-1.1zm1.66-12a1 1 0 00-1.66 1.1l1.66-1.1zM25.25 15l.83.55a1 1 0 000-1.1l-.83.55zm-3.17 6.55l4-6-1.66-1.1-4 6 1.66 1.1zm4-7.1l-4-6-1.66 1.1 4 6 1.66-1.1z"
          />
        </svg>
      );
    case ControlIconType.ROTATE:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="none"
          viewBox="0 0 30 30"
        >
          <title>Rotate piece</title>
          <path
            fill="currentColor"
            d="M20.09 16.12a1 1 0 10-1.93.53l1.93-.53zm7.96 9.13a1 1 0 10.26-1.99l-.26 1.99zm-7.02-1.91l-.97.26a1 1 0 00.84.73l.13-1zm-2.87-6.7l1.9 6.96 1.93-.53-1.9-6.95-1.93.53zm2.74 7.69l7.15.92.26-1.99-7.15-.92-.26 1.99z"
          />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
            d="M8.39 22.5a10 10 0 1113.23 0"
          />
        </svg>
      );
    case ControlIconType.FLIP:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="none"
          viewBox="0 0 30 30"
        >
          <title>Flip piece</title>
          <path
            stroke="currentColor"
            strokeDasharray="2 1"
            strokeWidth="2"
            d="M15 5v20"
          />
          <path
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M11 23V7L2 23h9zM28 23L19 7v16h9z"
          />
        </svg>
      );
    default:
      return null;
  }
}

export default ControlIcon;
