import { Color, GameState } from './state';
import { serialize } from './serialize';

function message(nextState: GameState): string {
  const link = new URL(
    `game?state=${serialize(nextState)}`,
    window.location.origin,
  ).toString();
  switch (nextState.currentTurn) {
    case null:
      return `Game over! Link to the final score: ${link}`;
    default:
      const nextName = nextState.players[nextState.currentTurn].name;
      if (nextState.board.length === 0) {
        return `Let's play! It's ${nextName}'s turn. Start here: ${link}`;
      } else {
        return `It's ${nextName}'s turn! Updated game link: ${link}`;
      }
  }
}

export function smsUrl(nextState: GameState, activePlayerColor: Color): string {
  const addresses = [Color.BLUE, Color.GREEN, Color.RED, Color.YELLOW]
    .filter(color => color !== activePlayerColor)
    .map(color => nextState.players[color].phone)
    .join(',');
  const body = message(nextState);
  if (navigator.userAgent.toLowerCase().includes('android')) {
    return `mms:${addresses}?body=${encodeURIComponent(body)}`;
  } else {
    return `sms://open?addresses=${addresses}&body=${encodeURIComponent(body)}`;
  }
}
