import { Color, getShape, ShapeName } from './state';
import styles from './PieceButtonImage.module.css';
import playerClass from './playerClass';

const SIZE_FACTOR = 8;

function PieceButtonImage({
  shape,
  color,
}: {
  shape: ShapeName;
  color: Color;
}) {
  const points = getShape(shape);
  const maxX = Math.max(...points.map(({ x }) => x)) + 1;
  const maxY = Math.max(...points.map(({ y }) => y)) + 1;
  const strokeWidth = 0.125;
  const width = maxX + strokeWidth * 2;
  const height = maxY + strokeWidth * 2;
  const viewBox = [-strokeWidth, -strokeWidth, width, height];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox.join(' ')}
      width={width * SIZE_FACTOR}
      height={height * SIZE_FACTOR}
    >
      {points.map(({ x, y }) => (
        <rect
          key={`grid:${x}:${y}`}
          x={x}
          y={y}
          width="1"
          height="1"
          strokeWidth="0.125"
          fill="none"
          className={styles.space}
        />
      ))}
      {points.map(({ x, y }) => (
        <rect
          key={`piece:${x}:${y}`}
          x={x}
          y={y}
          width="1"
          height="1"
          className={`${styles.piece} ${playerClass(color, styles)}`}
        />
      ))}
    </svg>
  );
}

export default PieceButtonImage;
