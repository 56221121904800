import { useLocation } from 'react-router-dom';
import GameBoard from './GameBoard';
import {
  ALL_SHAPE_NAMES,
  Board,
  Color,
  Flip,
  GameState,
  getCenter,
  getScore,
  getWinners,
  isValid,
  nextPlayer,
  nextRotation,
  Point,
  Rotation,
  ShapeName,
} from './state';
import { useState, Fragment } from 'react';
import styles from './Game.module.css';
import ColorIcon from './ColorIcon';
import Footer from './Footer';
import PieceButtonImage from './PieceButtonImage';
import playerClass from './playerClass';
import ControlIcon, { ControlIconType } from './ControlIcon';
import { useActivePlayer } from './useActivePlayer';
import { smsUrl } from './sms';
import { deserialize } from './serialize';
import { useTurnSent } from './useTurnSent';
import confetti from 'canvas-confetti';
import { getColors } from './getColors';
import NewGameButton from './NewGameButton';

function Game() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const state: GameState = deserialize(params.get('state')!);

  const [shapeNameToAdd, setShapeNameToAdd] = useState<ShapeName>();
  const [positionToAdd, setPositionToAdd] = useState<Point>();
  const [rotationToAdd, setRotationToAdd] = useState<Rotation>(0);
  const [flipToAdd, setFlipToAdd] = useState<Flip>(false);

  const [activePlayerColor, setActivePlayerPhone] = useActivePlayer(state);

  const setClampedPositionToAdd = (point: Point) => {
    setPositionToAdd({
      x: Math.max(-2, Math.min(22, point.x)),
      y: Math.max(-2, Math.min(22, point.y)),
    });
  };

  const setRelativePositionToAdd = (amount: Point) => {
    if (positionToAdd) {
      setClampedPositionToAdd({
        x: positionToAdd.x + amount.x,
        y: positionToAdd.y + amount.y,
      });
    }
  };

  const [hideSendUi, setHideSendUi] = useTurnSent(params.get('state')!);
  const [showScore, setShowScore] = useState(false);

  if (activePlayerColor === undefined) {
    return (
      <section className={`container ${styles.playerSelect}`}>
        <h1>Who are you?</h1>
        <ColorIcon color={Color.BLUE} />
        <button
          className="button"
          onClick={() => setActivePlayerPhone(state.players[Color.BLUE].phone)}
        >
          {state.players[Color.BLUE].name}
        </button>
        <ColorIcon color={Color.GREEN} />
        <button
          className="button"
          onClick={() => setActivePlayerPhone(state.players[Color.GREEN].phone)}
        >
          {state.players[Color.GREEN].name}
        </button>
        <ColorIcon color={Color.RED} />
        <button
          className="button"
          onClick={() => setActivePlayerPhone(state.players[Color.RED].phone)}
        >
          {state.players[Color.RED].name}
        </button>
        <ColorIcon color={Color.YELLOW} />
        <button
          className="button"
          onClick={() =>
            setActivePlayerPhone(state.players[Color.YELLOW].phone)
          }
        >
          {state.players[Color.YELLOW].name}
        </button>
      </section>
    );
  } else {
    if (activePlayerColor === state.currentTurn) {
      const usedShapeNames = new Set(
        state.board
          .filter(({ color }) => color === activePlayerColor)
          .map(({ shape }) => shape),
      );
      const availablePieces = [...ALL_SHAPE_NAMES]
        .filter(name => !usedShapeNames.has(name))
        .reverse();
      const pieceToAdd =
        shapeNameToAdd && positionToAdd
          ? {
              color: activePlayerColor,
              shape: shapeNameToAdd,
              position: positionToAdd,
              rotation: rotationToAdd,
              flip: flipToAdd,
            }
          : null;
      const nextBoard: Board = pieceToAdd
        ? [...state.board, pieceToAdd]
        : state.board;
      const valid = isValid(nextBoard);
      const nextState: GameState = {
        players: state.players,
        currentTurn: nextPlayer({ ...state, board: nextBoard }),
        board: nextBoard,
        forfeited: state.forfeited,
      };
      const nextStateUrl = smsUrl(nextState, activePlayerColor);
      const forfeitedPlayersIfForfeit = [...state.forfeited, activePlayerColor];
      const nextStateIfForfeit: GameState = {
        players: state.players,
        currentTurn: nextPlayer({
          ...state,
          forfeited: forfeitedPlayersIfForfeit,
        }),
        board: state.board,
        forfeited: forfeitedPlayersIfForfeit,
      };
      const nextStateIfForfeitUrl = smsUrl(
        nextStateIfForfeit,
        activePlayerColor,
      );
      return (
        <section className="container rows">
          <GameBoard
            state={state}
            currentPiece={pieceToAdd}
            onClick={point => {
              if (shapeNameToAdd && !positionToAdd) {
                const centerOffset = getCenter({
                  color: activePlayerColor,
                  shape: shapeNameToAdd,
                  position: { x: 0, y: 0 },
                  rotation: rotationToAdd,
                  flip: flipToAdd,
                });
                setClampedPositionToAdd({
                  x: point.x - centerOffset.x,
                  y: point.y - centerOffset.y,
                });
              }
            }}
          />
          <div className={styles.gameUi}>
            <a
              className={['button', (!valid || !shapeNameToAdd) && 'disabled']
                .filter(Boolean)
                .join(' ')}
              href={nextStateUrl}
              onClick={() => setHideSendUi(true)}
            >
              Send
            </a>
            <div className={['button-group', styles.pieceControls].join(' ')}>
              <button
                className={['button', 'no-padding', styles.controlButton].join(
                  ' ',
                )}
                disabled={!shapeNameToAdd}
                onClick={() => setRelativePositionToAdd({ x: -1, y: 0 })}
              >
                <ControlIcon type={ControlIconType.LEFT} />
              </button>
              <button
                className={['button', 'no-padding', styles.controlButton].join(
                  ' ',
                )}
                disabled={!shapeNameToAdd}
                onClick={() => setRelativePositionToAdd({ x: 0, y: -1 })}
              >
                <ControlIcon type={ControlIconType.UP} />
              </button>
              <button
                className={['button', 'no-padding', styles.controlButton].join(
                  ' ',
                )}
                disabled={!shapeNameToAdd}
                onClick={() => setRelativePositionToAdd({ x: 0, y: 1 })}
              >
                <ControlIcon type={ControlIconType.DOWN} />
              </button>
              <button
                className={['button', 'no-padding', styles.controlButton].join(
                  ' ',
                )}
                disabled={!shapeNameToAdd}
                onClick={() => setRelativePositionToAdd({ x: 1, y: 0 })}
              >
                <ControlIcon type={ControlIconType.RIGHT} />
              </button>
              <button
                className={['button', 'no-padding', styles.controlButton].join(
                  ' ',
                )}
                disabled={!shapeNameToAdd}
                onClick={() => setRotationToAdd(nextRotation(rotationToAdd))}
              >
                <ControlIcon type={ControlIconType.ROTATE} />
              </button>
              <button
                className={['button', 'no-padding', styles.controlButton].join(
                  ' ',
                )}
                disabled={!shapeNameToAdd}
                onClick={() => setFlipToAdd(!flipToAdd)}
              >
                <ControlIcon type={ControlIconType.FLIP} />
              </button>
            </div>
            <div className={styles.availablePieces}>
              {availablePieces.map(name => (
                <button
                  key={name}
                  className={[
                    'button',
                    'no-padding',
                    styles.pieceButton,
                    shapeNameToAdd === name && styles.active,
                    playerClass(activePlayerColor, styles),
                  ]
                    .filter(Boolean)
                    .join(' ')}
                  onClick={() => {
                    if (shapeNameToAdd === name) {
                      setShapeNameToAdd(undefined);
                      setPositionToAdd(undefined);
                    } else {
                      setShapeNameToAdd(name);
                    }
                  }}
                >
                  <PieceButtonImage shape={name} color={activePlayerColor} />
                </button>
              ))}
            </div>
            <a
              className={['button', !!shapeNameToAdd && 'disabled']
                .filter(Boolean)
                .join(' ')}
              href={nextStateIfForfeitUrl}
            >
              Forfeit
            </a>
            {shapeNameToAdd && !positionToAdd ? (
              <div className={styles.uiCover}>
                <div className={styles.message}>
                  <span>
                    Select a location on the board to place your piece.
                  </span>
                  <span>
                    Then you can fine tune the position with the arrows, rotate,
                    and flip buttons.
                  </span>
                  <button
                    className="button compact"
                    onClick={() => setShapeNameToAdd(undefined)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : null}
            {hideSendUi ? (
              <div className={styles.uiCover}>
                <div className={styles.message}>
                  <span>Turn sent.</span>
                  <span>
                    Didn't send?{' '}
                    <a className="link compact" href={nextStateUrl}>
                      Re-send
                    </a>
                  </span>
                  <button
                    className="button compact"
                    onClick={() => setHideSendUi(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          <Footer />
        </section>
      );
    } else {
      if (state.currentTurn !== null) {
        const currentName = state.players[state.currentTurn].name;
        return (
          <section className="container rows">
            <GameBoard state={state} />
            <div
              className={`${styles.playerMessage} ${playerClass(
                state.currentTurn,
                styles,
              )}`}
            >
              {currentName}'s turn. {currentName} will send an updated link once
              they're done.
            </div>
            <Footer />
          </section>
        );
      } else {
        const winners = getWinners(state);
        const scores = [...getScore(state).entries()].sort(([_a, a], [_b, b]) =>
          a > b ? -1 : 1,
        );
        const tie = winners.length > 1;
        return (
          <section className="container rows">
            <GameBoard
              state={state}
              animated={true}
              onAnimationComplete={() => {
                const winningColors = getColors(getWinners(state));
                function fire(particleRatio: number, opts: confetti.Options) {
                  confetti({
                    ...opts,
                    origin: { y: 0.7 },
                    colors: winningColors,
                    particleCount: Math.floor(200 * particleRatio),
                  });
                }
                fire(0.25, {
                  spread: 26,
                  startVelocity: 55,
                });
                fire(0.2, {
                  spread: 60,
                });
                fire(0.35, {
                  spread: 70,
                  decay: 0.91,
                  scalar: 0.8,
                });
                fire(0.1, {
                  spread: 120,
                  startVelocity: 25,
                  decay: 0.92,
                  scalar: 1.2,
                });
                fire(0.1, {
                  spread: 120,
                  startVelocity: 45,
                });
                setShowScore(true);
              }}
            />
            <div className={styles.finalScore}>
              <span
                className={[
                  styles.winner,
                  !tie && playerClass(winners[0], styles),
                ]
                  .filter(Boolean)
                  .join(' ')}
              >
                {tie ? "It's a tie!" : `${state.players[winners[0]].name}!`}
              </span>
              {scores.map(([player, score]) => (
                <Fragment key={`player-${player}-score`}>
                  <span>{state.players[player].name}</span>
                  <span className={styles.score}>{score}</span>
                </Fragment>
              ))}
              <div
                className={[styles.suspenseCover, showScore && styles.hidden]
                  .filter(Boolean)
                  .join(' ')}
              >
                And the winner is... 🥁
              </div>
            </div>
            <NewGameButton state={state} />
            <a className="button" href="/">Start a new game</a>
            <Footer />
          </section>
        );
      }
    }
  }
}

export default Game;
