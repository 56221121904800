import CloseButton from './CloseButton';
import { useActivePhone } from './useActivePlayer';
import styles from './Settings.module.css';
import { useHighContrast } from './useHighContrast';

function Settings() {
  const [phone, setPhone] = useActivePhone();
  const [highContrast, setHighContrast] = useHighContrast();
  return (
    <section className="container rows">
      <header className="header">
        <h1>Settings</h1>
        <CloseButton />
      </header>
      <div className={styles.settingRow}>
        <span>Current Device: {phone || '(not set)'}</span>
        <button
          className="button compact"
          onClick={() => setPhone(undefined)}
          disabled={phone === undefined}
        >
          Clear
        </button>
      </div>
      <div className={styles.settingRow}>
        <span>High contrast mode:</span>
        <input
          type="checkbox"
          checked={highContrast}
          onChange={evt => setHighContrast(evt.target.checked)}
        />
      </div>
    </section>
  );
}

export default Settings;
