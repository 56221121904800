import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <Link className="link" to="/settings">
        Settings
      </Link>
      <Link className="link" to="/how-to-play">
        How to play
      </Link>
    </footer>
  );
}

export default Footer;
